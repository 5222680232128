.title {
    display: flex;
    flex-grow: 1;
}

.pages {
    margin-top: 50px;
}

.topBtns {
    margin: 20px 0px;
    display: flex;
    flex-direction: row-reverse;
    flex-grow: 1;
}

.topBtns button {
    margin-left: 20px;
}

.dataCard {
    width: 200px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}

.dataCardTitle {
    color: #999;
}

.dataCardNumber {
    font-size: 32px;
}

.pagesData {
    display: flex;
}

.form {
    display: flex;
    flex-direction: column;
}

.form .formField {
    margin: 20px;
}

.btn {
    margin-top: 20px;
}

.menuBtn {
    cursor: pointer;
}

.menuItem a {
    text-decoration: none;
    color: #333;
}

.oddSettings {
    display: flex;
    justify-content: space-between;
    padding: 0px 30px;
}

.oddSetting {
    display: flex;
    flex-direction: column;
}

.fieldLabel {
    margin-bottom: 20px;
}

.highlightWordColorText input {
    color: #333;
}

.title a {
    color: #fff;
    text-decoration: none;
}

a {
    text-decoration: none;
}
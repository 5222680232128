.logo {
    width: 200px;
  }

.app {
    position: relative;
    margin: auto;
    width: 400px;
    padding-top: 40px;
}

.loginForm {
    display: flex;
    flex-direction: column;
}

.email, .password {
    margin: 20px 0px !important;
}

.error {
    color: #ff0000;
}